import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, Pagination, TagCard } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Tags page (/tags/)
*
* Loads all posts for the requested tag incl. pagination.
*
*/
const Tags = ({ data, location, pageContext }) => {
    const tags = data.allGhostTag.edges

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="series"
            />
            <Layout>
                <div className="container">
                    <section className="post-feed">
                        { tags.map((tag, index) => {
                            return (                                            
                                <TagCard tag={tag.node}></TagCard>
                            )
                        })}  
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    )
}

Tags.propTypes = {
    data: PropTypes.shape({
        // allGhostTag: PropTypes.arrayOf(
        //     PropTypes.shape({
        //         name: PropTypes.string.isRequired,
        //         slug: PropTypes.string,
        //         description: PropTypes.string,
        //         url: PropTypes.string,
        //         postCount: PropTypes.number
        // })),
        allGhostTag: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Tags

export const pageQuery = graphql`
    query GhostTagsQuery($limit: Int!, $skip: Int!) {
        allGhostTag(
            sort: { order: ASC, fields: name },
            filter: {visibility: {eq: "public"}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                    slug
                    url
                    postCount
                    name
                    feature_image
                }
            }
        }
    }
`
